@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans 3', sans-serif;
  overflow-y: auto;
  overflow-x: hidden;
}


.app {
  display: flex;
  position: relative;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-track:hover {
  background: #555;
}
.vis-item.vis-background.positive {
  background-color: #F8FAFC;
  opacity: 0.8;
  z-index: 0;
}
.vis-item.vis-background.negative {
  background-color: white;
  opacity: 0.8;
  z-index: 0;
}

.vis-line {
  border-left-style: dotted !important;
  border-color: #D2D9FD !important;
}

.vis-timeline {
  background: white;
}

.vis-item.vis-box {
  border: 0px;
  border-radius: 3px;
  font-size: 8pt;
  width: 120px;
  height: 30px;
  margin-left: 50px;
}

.vis-item .vis-item-content {
  padding: 0 !important;
  width: 100%;
  height: 100%;
}

.vis-item .vis-item-content>div:first-of-type {
  display: flex;
  width: 100%;
  height: 100%;
}

.rvo-dot:after {
  content: " ";
  position: absolute;
  top: -8px;
  left: 2px;
  transform: translate(50%, 50%) scale(1);
  width: 8px;
  height: 8px;
  background-color: #5096FF;
  border-radius: 50%;
}

.vis-item.vis-dot{
  background-color: #5096FF !important;
  border-color: #5096FF !important;
}

.vis-timeline .vis-grid.vis-vertical {
  display: none;
}

/* .vis-time-axis .vis-text.vis-major {
  position: relative;
  top: -15px;
}


.vis-time-axis .vis-text.vis-minor {
  position: relative;
  top: 25px;
} */

