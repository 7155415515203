.timeline-fragment {
    display: flex;
}

.solid {
    border-top: 3px solid #756798;
}

.dotted {
    border-top: 3px dashed #756798;
}

.timeline {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
    height: 20px;
}

.months {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-flow: row nowrap;
    margin-bottom: 20px;
}

.month {
    text-align: center;
    border: 1px solid LightGray;
    border-radius: 5px;
    margin: 2px;
    color: #424949;
}

.symbol {
    position: absolute;
    display: flex;
    color: rgb(66, 73, 73);
}

.vl-green {
    border-left: 2px solid green;
    cursor: pointer;
}

.vl-red {
    border-left: 2px solid red;
    cursor: pointer;
}

.stage {
    position: absolute;
    background-color: #756798 !important;
    color: #FFF !important;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border: 1px solid #fff !important;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;  
}

.timeline-main {
    flex: 1 1 0%;
    min-height: 300px;
    border-radius: 10px;
    background: rgb(33, 21, 64);
    display: flex;
    flex-direction: column;
    box-shadow: black 2.4px 2.4px 3.2px;
}

.dot {
    position: absolute;
    color: #756795;
    font-size: 20px;
    left: 99.4%
}

.pill-wrapper {
    position: absolute;
    cursor: pointer;
}

.pill-wrapper:hover {
    z-index: 1000;
}

.stage:hover {
    z-index: 1000;
    cursor: pointer;
}

.pill {
    padding: 10px;
    color: #756798 !important;
    height: 30px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    border: 1px solid #B5C9DD !important;
    background-color: #E8EEF4 !important;
    border-radius: 6px;
}

.pill-line {
    height: 20px;
    width: 90px;
    background: linear-gradient(#B5C9DD, #B5C9DD) no-repeat center/2px 100%;
}

.activity-dot {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5px;
    color: #B5C9DD;
}

.date {
    color: black;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.date-wrapper {
    margin-left: 5px;
    display: flex;
    flex-direction: column;
}

.timeline-icon {
    width: 13px !important;
    height: 13px !important;
}